@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

small {
  font-size: 80%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, img {
  display: block;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

blockquote, q {
  quotes: none;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
  color: inherit;
}

:focus {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul, ol {
  margin-left: 0;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

button, input[type=button], input[type=reset], input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
  overflow: visible;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input,
button,
textarea,
select {
  font: inherit;
}

embed,
iframe,
object {
  max-width: 100%;
}

strong {
  font-weight: bold;
}

img {
  max-width: 100%;
  display: block;
}

:root {
  --color_purple:#3A0CA3;
  --color_blue:#4361EE;
  --color_black:#141515;
  --color_white:#ffffff;
  --color_gray:#EDFAFE;
  --color_gray_dark:#aaaaaa;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--color_black);
  background: white;
  font: 400 1.8rem/1.75 "Nunito", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::placeholder {
  color: #999;
  opacity: 1;
}

input:valid {
  color: green;
}

input:invalid {
  color: red;
}

#root {
  height: 100%;
  overflow: scroll;
}
@media (min-width: 830px) {
  #root {
    padding-top: 80px;
  }
}
@media screen and (min-width: 1000px), and screen and (min-height: 700px) {
  #root {
    padding-top: 10.5vmin;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color_black);
  margin-bottom: 2rem;
  line-height: 1.1;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  color: var(--color_purple);
  font-weight: 700;
}

h1 {
  font-weight: 400;
  font-size: 3.2rem;
  font-size: clamp(3.2rem, 2.7259rem + 2.3704vw, 4.8rem);
}
h1.home {
  font-size: 3rem;
  font-size: clamp(3.5rem, 2.7593rem + 3.7037vw, 6rem);
}

h2 {
  font-size: 2.2rem;
  font-size: clamp(2.2rem, 1.963rem + 1.1852vw, 3rem);
}

.container {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
}

.btn {
  color: white;
  border: 0;
  height: 5.6rem;
  padding: 0 3rem;
  font-size: 1.8rem;
  display: inline-flex;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 60px;
  text-decoration: none;
  color: white;
  background: var(--color_purple);
  transition: all 350ms ease-in-out;
}
.btn:hover {
  background: var(--color_blue);
}
.btn.skinny {
  height: 4rem;
  font-size: 1.6rem;
  padding: 0 2rem;
}
.btn.blue {
  background: var(--color_blue);
}
.btn.blue:hover {
  background: var(--color_purple);
}

header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--color_gray);
  z-index: 2000;
  padding: 1rem 3rem;
}
header .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2rem;
}
header .logo {
  display: inline-flex;
  text-decoration: none;
}
@media (min-width: 830px) {
  header {
    position: fixed;
  }
  header .container {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.wallet-buttons {
  position: relative;
}
.wallet-buttons .disconnect-wallet {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(110%);
  pointer-events: none;
  opacity: 0;
}
.wallet-buttons .disconnect-wallet.display {
  pointer-events: auto;
  opacity: 1;
}

.add-collection {
  background: none;
  border: 0;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  align-self: center;
  transition: all 350ms ease-in-out;
  display: none;
}
@media (min-width: 500px) {
  .add-collection {
    display: inline-flex;
  }
}
.add-collection:hover {
  color: var(--color_blue);
}

nav {
  display: flex;
  grid-gap: 1rem;
}
nav a {
  color: var(--color_black);
  font-weight: 700;
  text-decoration: none;
}

.network-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 3rem;
}
.network-dropdown option {
  display: flex;
  align-items: center;
}

.gm-wrapper {
  padding: 0 3rem;
  max-width: 140rem;
  margin: 0 auto;
  padding-top: 3rem;
}
@media (min-width: 830px) {
  .gm-wrapper {
    padding-top: 0;
  }
}
.gm-wrapper .gm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5rem;
  margin-bottom: 5rem;
}
@media (min-width: 1000px) {
  .gm-wrapper .gm {
    flex-wrap: nowrap;
  }
}
.gm-wrapper .page-intro {
  width: 100%;
  text-align: center;
  margin-bottom: 5rem;
  padding: 0 3rem;
}
.gm-wrapper .connect-to-inner {
  text-align: center;
  margin-top: 3rem;
}
.gm-wrapper .connect-to-inner p {
  margin-bottom: 1.5rem;
}
.gm-wrapper .connect-to-inner select {
  position: relative;
  top: -3px;
}
.gm-wrapper .connect-to-inner select + .btn {
  margin-left: 1.5rem;
}
.gm-wrapper .download-left {
  width: 100%;
}
@media (min-width: 1000px) {
  .gm-wrapper .download-left {
    flex-basis: 48%;
  }
}
.gm-wrapper .download-left .image-toggle {
  background: white;
  border: 2px solid var(--color_blue);
  border-radius: 2.5rem;
  display: flex;
  flex-wrap: nowrap;
  height: 4.4rem;
  top: 5rem;
  width: 25.5rem;
  z-index: 250;
  justify-content: space-between;
  margin: 2.5rem auto 0 auto;
}
.gm-wrapper .download-left .image-toggle button {
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 2.5rem;
  height: 4rem;
  background: transparent;
  color: black;
  text-align: center;
  padding: 0 2rem;
  border: 0;
  width: 100%;
}
.gm-wrapper .download-left .image-toggle button.active {
  background: var(--color_blue);
  color: white;
}
.gm-wrapper .download-left .inner {
  background: rgba(255, 255, 255, 0.5);
  border: 4px solid #e0dbec;
  border-radius: 1rem;
  padding: 5.5rem 3rem;
  text-align: center;
}
.gm-wrapper .download-left .inner .toggle-top {
  display: none;
}
.gm-wrapper .download-left .inner.collapsed {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.gm-wrapper .download-left .inner.collapsed .toggle-top {
  display: inline-flex;
}
.gm-wrapper .download-left .inner.collapsed form {
  display: none;
}
.gm-wrapper .download-left .inner + .inner {
  margin-top: 2rem;
}
.gm-wrapper .wallet-contents {
  display: grid;
  grid-gap: 1rem;
  max-height: 20rem;
  overflow: scroll;
  grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
}
.gm-wrapper .wallet-contents .asset {
  border: 3px solid var(--color_blue);
  border-radius: 1rem;
  overflow: hidden;
  width: 8.5rem;
  cursor: pointer;
  opacity: 0.8;
  transition: all 350ms ease-in-out;
}
.gm-wrapper .wallet-contents .asset img {
  max-width: 100%;
  height: auto;
}
.gm-wrapper .wallet-contents .asset:hover {
  border-color: var(--color_purple);
  opacity: 1;
}
.gm-wrapper .canvas-outter-wrap {
  width: 100%;
  position: relative;
}
@media (min-width: 1000px) {
  .gm-wrapper .canvas-outter-wrap {
    max-width: 52%;
  }
}
.gm-wrapper .canvas-outter-wrap .download {
  position: absolute;
  width: 21.5rem;
  bottom: 0;
  left: 50%;
  height: 5rem;
  transform: translate3d(-50%, 40%, 0);
}
.gm-wrapper .canvas-outter-wrap .image-toggle {
  background: white;
  border: 2px solid var(--color_blue);
  border-radius: 2.5rem;
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  left: 50%;
  height: 4.4rem;
  top: 2rem;
  width: 25.5rem;
  justify-content: space-between;
  transform: translateX(-50%);
}
.gm-wrapper .canvas-outter-wrap .image-toggle button {
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 2.5rem;
  height: 4rem;
  background: transparent;
  color: black;
  text-align: center;
  padding: 0 2rem;
  border: 0;
}
.gm-wrapper .canvas-outter-wrap .image-toggle button.active {
  background: var(--color_blue);
  color: white;
}
.gm-wrapper #canvas-wrap {
  border-radius: 1rem;
  border: 4px solid var(--color_blue);
  overflow: hidden;
  display: flex;
  position: relative;
  width: 100%;
}
.gm-wrapper #canvas-wrap:before, .gm-wrapper #canvas-wrap:after {
  position: absolute;
  content: "";
  display: block;
  pointer-events: none;
  opacity: 0;
}
.gm-wrapper #canvas-wrap:after {
  width: 100%;
  height: 100%;
  background: rgba(243, 243, 255, 0.65);
  z-index: 2;
  transition: all 350ms ease-in-out;
  z-index: 2;
}
.gm-wrapper #canvas-wrap:before {
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 50%;
  border: 10px solid var(--color_blue);
  border-top-color: var(--color_black);
  animation: loading 2s linear infinite;
  z-index: 3;
}
.gm-wrapper #canvas-wrap.loading:before, .gm-wrapper #canvas-wrap.loading:after {
  opacity: 1;
  pointer-events: auto;
}
.gm-wrapper canvas {
  width: 100%;
  height: 100%;
  background: var(--color_gray) no-repeat bottom center/cover;
  transition: all 350ms ease-in-out;
}
.gm-wrapper canvas .canvas-loader {
  background: var(--color_black);
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: all 350ms ease-in-out;
}
.gm-wrapper form {
  display: flex;
  grid-gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}
.gm-wrapper label {
  font-weight: 700;
}
.gm-wrapper input {
  border: 0;
  height: 5.6rem;
  font-family: "Nunito", "Arial", sans-serif;
  font-size: 2.4rem;
  text-align: center;
  max-width: 17.5rem;
  padding: 0 1.5rem;
  background: transparent;
}
.gm-wrapper .field {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: 3px solid var(--color_blue);
  border-radius: 20px;
  width: 100%;
  max-width: 38rem;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1000px) {
  .gm-wrapper .field {
    border-radius: 50px;
    flex-wrap: nowrap;
  }
}
.gm-wrapper .field button {
  background: var(--color_blue);
  color: white;
  width: 100%;
  padding: 0 1rem;
  border-radius: 0 0 10px 10px;
}
@media (min-width: 1000px) {
  .gm-wrapper .field button {
    border-radius: 0 50px 50px 0;
  }
}
.gm-wrapper .field button:hover {
  background: var(--color_purple);
}
.gm-wrapper .throw_error {
  width: 100%;
  font-weight: 700;
  color: var(--color_black);
  grid-column-end: span 6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

footer {
  width: 100%;
  margin-top: 2.5rem;
  text-align: center;
  font-style: italic;
  padding: 1rem 3rem 2rem;
}
footer p {
  font-size: 1.5rem;
}
footer a {
  color: var(--color_black);
  text-decoration: none;
  font-weight: 700;
  transition: all 350ms ease-in-out;
}
footer a:hover {
  color: var(--color_purple);
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
.home-inner {
  width: 100%;
  text-align: center;
  padding: 0 3rem;
  padding-top: 3rem;
}
@media (min-width: 830px) {
  .home-inner {
    padding-top: 1.5rem;
  }
}
@media (min-width: 1300px) {
  .home-inner {
    padding: 0;
  }
}

.home-boxes {
  display: grid;
  flex-wrap: wrap;
  grid-column-gap: 2.5rem;
  grid-row-gap: 7.5rem;
  justify-content: center;
  max-width: 126rem;
  margin: 6.5rem auto 10rem auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
@media (min-width: 830px) {
  .home-boxes {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1300px) {
  .home-boxes {
    padding: 0;
  }
}
.home-boxes a {
  border: 3px solid var(--color_blue);
  border-radius: 10px 10px 0 0;
  height: 0;
  padding-bottom: calc(100% - 6px);
  position: relative;
  text-decoration: none;
  width: 100%;
  transition: all 350ms ease-in-out;
}
.home-boxes a img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px 7px 0 0;
}
.home-boxes a span {
  width: calc(100% + 6px);
  background: var(--color_blue);
  color: white;
  text-align: center;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
  position: absolute;
  z-index: 2;
  left: -3px;
  bottom: 0;
  transform: translateY(100%);
  transition: all 350ms ease-in-out;
}
.home-boxes a:hover {
  border-color: var(--color_purple);
}
.home-boxes a:hover span {
  background: var(--color_purple);
}

.not-here {
  margin-top: 6rem;
}
.not-here h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-size: clamp(1.8rem, 1.6222rem + 0.8889vw, 2.4rem);
}
.not-here p {
  margin-bottom: 2rem;
}

.page-wrap.wallpaper .download-left {
  flex: 1 1;
}
.page-wrap.wallpaper .canvas-outter-wrap {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  right: 0;
  top: 0;
  height: 100%;
  flex-direction: column;
  position: relative;
}
@media (min-width: 1000px) {
  .page-wrap.wallpaper .canvas-outter-wrap {
    min-height: calc(100vh - 64px);
    flex: 0.75 1;
  }
}
@media (max-width: 700px) {
  .page-wrap.wallpaper .gm-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap {
  padding: 1.25vmin;
  border-radius: 5.5vmin;
  position: relative;
  overflow: hidden;
  font-size: 0;
  border: 2px solid #444;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: border-color 0.2s ease-in-out;
}
@media (max-width: 700px) {
  .gm-wrapper #canvas-wrap.canvas-wallpaper-wrap {
    padding: 2.5vw;
    border-radius: 9.8vw;
  }
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap button.download {
  transform: translate3d(-50%, -55%, 0);
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap:after {
  display: none;
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .canvas-wrap {
  aspect-ratio: 0.47/1;
  width: 100%;
  height: calc(85vmin - 50px);
  overflow: hidden;
  position: relative;
  mask-image: url(./images/wallpapers/wallpaper-mask.png);
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  background-color: #1a1a1a;
}
@media (max-width: 700px) {
  .gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .canvas-wrap {
    height: 125vw;
  }
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .phone-time,
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .phone-date {
  color: #fff;
  position: absolute;
  left: 0;
  z-index: 100;
  right: 0;
  line-height: 1;
  text-align: center;
  font-family: SF UI Display, -apple-system, system-ui, sans-serif;
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .phone-time {
  font-size: 7.5vmin;
  top: 10.8%;
  font-weight: 300;
  letter-spacing: -0.275vmin;
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .phone-date {
  font-size: 1.8vmin;
  top: 20.5%;
  font-weight: 400;
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap .phone-lockscreen {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-size: cover;
  background-image: url(./images/wallpapers/phone-lockscreen.png);
}
.gm-wrapper #canvas-wrap.canvas-wallpaper-wrap canvas {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #353535;
  transition: opacity 0.2s ease-in-out;
}
