.gm-wrapper.ucc .ulogo {
    width:100%;
    display:inline-flex;
    grid-gap:2rem;
    align-items:center;
    line-height:1;
    flex-wrap:wrap;
    justify-content:center;
    margin-bottom:3rem;
    margin-top:2rem;
    padding:0 1rem;
}

.gm-wrapper.ucc .ulogo svg {
    max-width:25rem;
}

.gm-wrapper.ucc .ulogo span {
    font-size:4rem;
    font-weight:700;
    display:block;
}

.gm-wrapper.ucc .btn {
    background:black;
}

.gm-wrapper.ucc h2 {
    font-weight:400;
}

.gm-wrapper.ucc h2 strong {
    color:black;
}

.gm-wrapper.ucc {
    max-width:156rem;
}

.gm-wrapper.ucc #canvas-wrap {
    border-color:black;
}

.gm-wrapper.ucc .download-left {
    background:#E6DDCE;
    border:3px solid black;
    border-radius:1rem;
}

.gm-wrapper.ucc .download-left .inner {
    border:none;
    padding:3rem;
}

.gm-wrapper.ucc .download-left .inner .bottom{
    padding-top:2rem;
    padding-bottom:2rem;
}

.gm-wrapper.ucc .download-left .inner.top {
    background:rgba(255,255,255,0.3);
}

.gm-wrapper.ucc .field button {
    background:black;
}

.gm-wrapper.ucc .field button:hover,
.gm-wrapper.ucc .btn:hover {
    background:#E6DDCE;
    color:black;
}

.gm-wrapper.ucc .field {
    border-color:black;
}

.options {
  padding-bottom:1rem;
  display:grid;
  grid-gap:1rem;
  max-height:45rem;
  overflow:scroll;
  grid-template-columns:1fr 1fr;
}

.bling {
  width:100%;
  cursor:pointer;
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  grid-gap:0.5rem;
}

.bling .img-wrap {
    overflow:hidden;
    border:3px solid black;
    opacity:0.4;
    border-radius:1rem;
    transition:all 350ms ease-in-out;
}

.bling .img-wrap img {
    max-width:100%;
    height:auto;
}

.bling p {
    text-align:center;
    font-size:1.4rem;
}

.bling:hover .img-wrap,
.bling:focus .img-wrap,
.bling.selected .img-wrap {
  opacity:1;
}
    
.bling.selected p {
    font-weight:$bold;
}

@media(min-width:500px) {
    .options {
        grid-template-columns:1fr 1fr 1fr;
    }
}

@media(min-width:768px) {
    .options {
        grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    }

    .ulogo {
        grid-gap:2rem;
        margin-top:0;
        flex-wrap:nowrap;
    }

    .ulogo span {
        font-size:5rem;
    }
}

@media(min-width:1000px) {
    .gm-wrapper.ucc .download-left {
        padding:4rem;
        flex-basis:52%;
    }
}